
import { unregister } from '@maxsystems/ui/mixins/pluggable'
import { bodyType } from '@maxsystems/ui/vue/filters'

import { dataSource } from './data-source'
import VDP from './VDP'
import ActionButtonGroup from '~/plugins/board/plugins/ActionButtonGroup'

export default {
  name: 'VdpRoute',
  metaInfo () {
    return {
      title: this.isLoading
        ? ''
        : [
            this.name,
            bodyType(this.ymmt.bodyType),
        `for Sale #${this.stockNumber}`
          ].filter(Boolean).join(' ')
    }
  },
  props: {
    inventory: {
      type: [Number, String],
      required: true
    }
  },
  components: { VDP },
  computed: {
    isLoading: () => Object.keys(dataSource.inventory).length === 0,
    name: vm => [
      vm.ymmt.year,
      vm.ymmt.make,
      vm.ymmt.model,
      vm.ymmt.trim
    ].filter(Boolean).join(' '),
    ymmt: () => dataSource.vehicle.ymmt || {},
    stockNumber: () => dataSource.inventory.stockNumber,
    dealer: () => dataSource?.dealer,
    analyticsData ({ dealer }) {
      const { inventory: { id, certified, price }, vehicle, vehicle: { condition, stockNumber } } = dataSource
      const { bodyType: segment, make, model, trim, year } = this.ymmt

      if (!dealer.id || !id) return

      return {
        id,
        certified: certified.isCertified,
        certifiedProgram: certified.name,
        condition,
        dealer: {
          id: dealer.id,
          name: dealer.name,
          city: dealer.address?.locality,
          state: dealer.address?.region,
          zip: dealer.address?.postalCode
        },
        exteriorColor: vehicle.color?.name,
        make,
        mileage: vehicle.odometer.value,
        model,
        premiumUpgradesValue: vehicle.options?.msrp?.amount,
        price: price.showroomExportPrice?.amount,
        segment,
        stockNumber,
        trim,
        vin: vehicle.vin,
        year
      }
    },

    dealerPhone ({ dealer }) {
      const buid = dealer.id
      if (!buid) return
      return this.$store.state.instance.config?.dealers?.[buid]?.phone || null
    }
  },
  created () {
    if (this.$route.meta.layout === 'embed') unregister('vdp', ActionButtonGroup)
  },
  methods: {
    track (inventory) {
      if (!inventory || !window) return
      window.dataLayer.push({ inventory })
    }
  },
  watch: {
    analyticsData: {
      handler (newValue) {
        if (!newValue) return
        this.track(newValue)
      },
      immediate: true
    }
  },
  render (h) {
    return h('VDP', {
      props: {
        dealerPhone: this.dealerPhone,
        inventory: this.inventory
      }
    })
  }
}
